import { makePostRequest } from '../components/shared/api.js';
import { POSTCODE_REGEX, PASSWORD_REGEX, USERNAME_REGEX, EMAIL_REGEX, PROXY_REGEX } from '../utils/constants.jsx'
import { getAddressData } from '../utils/postcodes.jsx'
import moment from 'moment'
import i18n from '../utils/i18n.js'
import dates from '../utils/dates'

document.addEventListener('DOMContentLoaded', () => {
  const signUpForm = document.getElementById('signup_form');

  if(signUpForm) {
    let errors = false
    const signUpButton = document.getElementById('signup_button');
    const acceptTermsField = document.getElementById('acceptTerms');
    const usernameField = document.getElementsByName('username')[0];
    const firstNameField = document.getElementsByName('first_name')[0];
    const emailField = document.getElementsByName('email')[0];
    const codeField = document.getElementsByName('code')[0];
    const postcodeField = document.getElementsByName('postcode')[0];
    const birthInput = document.getElementById('birth-inputs')
    let birthDay = null;
    let birthMonth = null;
    let birthYear = null;
    const passwordField = document.getElementsByName('password')[0];
    const passwordConfirmationField = document.getElementsByName('password_confirmation')[0];

    const usernameErrorField = usernameField.parentElement.getElementsByClassName('invalid-feedback')[0]
    const firstNameErrorField = firstNameField.parentElement.getElementsByClassName('invalid-feedback')[0]
    const emailErrorField = emailField.parentElement.getElementsByClassName('invalid-feedback')[0]
    const codeErrorField = codeField.parentElement.getElementsByClassName('invalid-feedback')[0]
    const postcodeErrorField = postcodeField.parentElement.getElementsByClassName('invalid-feedback')[0]
    const birthErrorField = birthInput.parentElement.getElementsByClassName('invalid-feedback')[0]
    const passwordErrorField = passwordField.parentElement.getElementsByClassName('invalid-feedback')[0]
    const passwordConfirmationErrorField = passwordConfirmationField.parentElement.getElementsByClassName('invalid-feedback')[0]

    let adminDistrictValue = '';
    let adminCountyValue = '';
    let regionFieldValue = '';
    let countryFieldValue = '';
    let longitudeFieldValue = '';
    let latitudeFieldValue = '';


    if(birthInput){
      birthInput.innerHTML = `
        <label class="label text-gray">Date of Birth</label>
        <div class='row'>
          <div class="form-input-cell select-cell col-md-3 col-sm-12 mb-2 mb-md-0"><select class="form-control undefined" id='birth_day'><option disabled="" selected="selected" value="">Day</option>${dayOptions()}</select></div>
          <div class="form-input-cell select-cell col-md-5 col-sm-12 mb-2 mb-md-0"><select class="form-control undefined" id='birth_month'><option disabled="" selected="selected" value="">Month</option>${monthOptions()}</select></div>
          <div class="form-input-cell select-cell col-md-4 col-sm-12 mb-2 mb-md-0"><select class="form-control undefined" id='birth_year'><option disabled="" selected="selected" value="">Year</option>${YearOptions()}</select></div>
        </div>
        `

      birthDay = document.getElementById('birth_day');
      birthMonth = document.getElementById('birth_month');
      birthYear = document.getElementById('birth_year');
    }

    function dayOptions() {
      let collection = ''
      Object.keys(dates.days).forEach(function (key) {
        collection += `<option value=${dates.days[key]}>${key}</option>`
      })

      return collection;
    }

    function monthOptions() {
      let collection = ''
      Object.keys(dates.months).forEach(function (key) {
        collection += `<option value=${dates.months[key]}>${key}</option>`
      })

      return collection;
    }

    function YearOptions() {
      let collection = ''
      Object.keys(dates.years.reverse()).forEach(function (key) {
        collection += `<option value=${dates.years[key]}>${dates.years[key]}</option>`
      })

      return collection;
    }

    function birthDateString() {
      return `${birthYear.value}-${birthMonth.value}-${birthDay.value}`
    }

    signUpForm.addEventListener('submit', async function (e) {
      e.preventDefault();
      e.stopPropagation();

      const formData = new FormData(e.target)
      clearErrors();
      validateData()

      Promise.all([checkEmailUniqueness(), checkUsernameUniqueness(), checkMarketingCodeValidity(), checkPostcodes()]).then(function ([email_result, username_result, code_result]) {
        if (email_result.email_exists) {
          addError(emailErrorField, 'You are already registered. Please log in.');
        }
        if (username_result.username_exists) {
          addError(usernameErrorField, 'Sorry, that username is not available.');
        }
        if (code_result.code_invalid) {
          addError(codeErrorField, 'Code not recognised. Leave blank if you do not have one.');
        }

        if (!errors) {
          const params = {
            user: {
              username: formData.get('username'),
              first_name: formData.get('first_name'),
              email: formData.get('email'),
              code: formData.get('code'),
              birth_date: birthDateString(),
              password: formData.get('password'),
              terms: true,
              account_type: 'direct',
              address_attributes: {
                post_code: formData.get('postcode'),
                admin_district: adminDistrictValue,
                admin_county: adminCountyValue,
                region: regionFieldValue,
                country: countryFieldValue,
                longitude: longitudeFieldValue,
                latitude: latitudeFieldValue
              }
            }
          }
          makePostRequest({ url: '/users', body: params  }).then((_response) => {
            signUpForm.parentElement.innerHTML = `
              <div class="mb-4 text-center text-md-left">
                <h2 class="mb-0">Welcome!</h2>
                <h3 class="text-blue-ui weight-normal">Thank you for joining.</h3>
              </div>
              <h4>You have signed up successfully. </br>Please check your email to finish the registration process.</h4>
            `
            clearErrors();
            document.getElementById('join').scrollIntoView({behavior: 'auto', block: 'center'})
          }).catch((_response) => addError(acceptTermsField, 'Something is wrong. Please try again later'));
        }
      });
    });

    acceptTermsField.addEventListener('change', function(e) {
      if (e.currentTarget.checked) {
        signUpButton.removeAttribute('disabled')
      } else {
        signUpButton.setAttribute('disabled', true)
      }
    })

    function clearErrors() {
      errors = false;

      usernameErrorField.innerHTML = ''
      usernameErrorField.style.display = 'none'

      emailErrorField.innerHTML = ''
      emailErrorField.style.display = 'none'

      codeErrorField.innerHTML = ''
      codeErrorField.style.display = 'none'

      firstNameErrorField.innerHTML = ''
      firstNameErrorField.style.display = 'none'

      postcodeErrorField.innerHTML = ''
      postcodeErrorField.style.display = 'none'

      birthErrorField.innerHTML = ''
      birthErrorField.style.display = 'none'

      passwordErrorField.innerHTML = ''
      passwordErrorField.style.display = 'none'

      passwordConfirmationErrorField.innerHTML = ''
      passwordConfirmationErrorField.style.display = 'none'
    }

    async function validateData() {
      validatePassword()
      validateBirthdayFields();
      validatePostCodeFields();
      await validateNameFields();
    };

    const validatePassword = function validatePassword() {
      if (!PASSWORD_REGEX.test(passwordField.value)) {
        addError(passwordErrorField, i18n.t('shared.passwordError'));
      } else if (!PASSWORD_REGEX.test(passwordConfirmationField.value)) {
        addError(passwordConfirmationErrorField, i18n.t('shared.passwordError'));
      } else if (passwordField.value !== passwordConfirmationField.value) {
        addError(passwordErrorField, 'Please ensure that your password and confirmed password are the same.');
        addError(passwordConfirmationErrorField, 'Please ensure that your password and confirmed password are the same.');
      }
    };

    function validatePostCodeFields() {
      if (!POSTCODE_REGEX.test(postcodeField.value)) {
        addError(postcodeErrorField, 'Please enter a valid postcode')
      }
    };

    async function validateNameFields() {
      validateEmail();
      validateUsername();
      validateFirstName();
    };

    const checkEmailUniqueness = () => {
      return makePostRequest({ url: '/users/check_email', body: { email: emailField.value }}, true)
    }

    const checkUsernameUniqueness = () =>{
      return makePostRequest({ url: '/users/check_username', body: { username: usernameField.value }}, true)
    }

    const checkMarketingCodeValidity = () =>{
      return makePostRequest({ url: '/users/check_code', body: { code: codeField.value }}, true)
    }

    const checkPostcodes = async () => {
      if (!POSTCODE_REGEX.test(postcodeField.value)) {
        return
      }
      const res = await getAddressData(postcodeField.value);
      if (res.status === 200) {
        const data = res.data;

        if (data.admin_district) adminDistrictValue = data.admin_district;
        if (data.admin_county) adminCountyValue = data.admin_county;
        if (data.region) regionFieldValue = data.region;
        if (data.country) countryFieldValue = data.country;
        if (data.longitude) longitudeFieldValue = data.longitude;
        if (data.latitude) latitudeFieldValue = data.latitude;

        if (data.postcode) postcodeField.value = data.postcode;
      } else {
        addError(postcodeErrorField, 'Please enter a valid postcode');
      }
    }

    function validateBirthdayFields() {
      const dateObj = moment(birthDateString(), "YYYY-MM-DD", true);
      const currentDate = moment();

      if (dateObj.isValid()) {
        if (dateObj > currentDate) {
          addError(birthErrorField, 'Date from the future');
        } else {
          const duration = moment.duration(currentDate.diff(dateObj)).asYears();

          if (duration < 18) {
            addError(birthErrorField, 'Sorry, you have to be 18 or over to join this site.');
          }
        }
      } else {
        addError(birthErrorField, 'Please give a valid date.');
      }
    };

    function validateFirstName() {
      const trimFirstName = firstNameField.value.trim();

      if (trimFirstName.length < 2) {
        addError(firstNameErrorField, 'The minimum length is two characters.')
      } else if (!PROXY_REGEX.test(trimFirstName)) {
        addError(firstNameErrorField, 'Sorry, special characters and numbers are not allowed.')

      }
    };

    function validateEmail() {
      if (!EMAIL_REGEX.test(emailField.value)) {
        addError(emailErrorField, 'Please provide a valid email address.')
      }
    };

    const validateUsername = function validateUsername() {
      const trimUsername = usernameField.value.trim();

      if (trimUsername.length < 2) {
        addError(usernameErrorField, 'The minimum length is two characters.');

      } else if (trimUsername.length > 15) {
        addError(usernameErrorField, 'The maximum length is fifteen characters.');

      } else if (!USERNAME_REGEX.test(trimUsername)) {
        addError(usernameErrorField, 'Sorry, special characters are not allowed. Numbers can be used with letters, but numbers alone are not allowed.');
      }
    };

    function addError(domObject, error_msg) {
      domObject.innerHTML = error_msg;
      domObject.style.display = 'block';
      errors = true;
    }
  }
  return true;
})
