export const makePutRequest = (data) => {
  return fetch(data.url, {
    body: JSON.stringify(data.body),
    method: 'PUT',
    headers: {
      'X-CSRF-Token': csrfToken(),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    'credentials': 'same-origin'
  }).then((response) => {
      if (response.statusText === 'No Content') {
        return { data: {}, status: response.status }
      } else {
        return response.clone().json().then(data => {
            if (response.status >= 400 && response.status < 500) {
              return Promise.reject(data);
            } else {
              return {
                data: data
              }
            }
          })
      }
    }
  )
}

export const makePostRequest = (data, returnPromise = false) => {
  return fetch(data.url, {
    body: JSON.stringify(data.body),
    method: 'POST',
    headers: {
      'X-CSRF-Token': csrfToken(),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    'credentials': 'same-origin'
  }).then((response) => {
      if (response.status === 204) {
        return { data: {}, status: response.status }
      }
      const promise = response.json()
      if(returnPromise) {
        return promise
      } else {
        return promise.then(data => {
          if (response.status >= 400 && response.status < 500) {
            return Promise.reject(data);
          } else {
            return {
              data: data
            }
          }
        })
      }
    }
  )
}

export const makeGetRequest = (data) => {
  return fetch(data.url, {
    headers: {
      'X-CSRF-Token': csrfToken(),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    'credentials': 'same-origin'
  }).then((response) =>
    response.json().then(data => ({
      data: data,
      status: response.status
    }))
  )
}

export const makeDeleteRequest = (data) => {
  return fetch(data.url, {
    method: 'DELETE',
    body: JSON.stringify(data.body),
    headers: {
      'X-CSRF-Token': csrfToken(),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    'credentials': 'same-origin'
  }).then((response) => {
    if (response.status === 204) {
      return { data: {}, status: response.status }
    }
    else {
      return (response.json().then(data => {
        return ({
        data: data,
        status: response.status
        })
      }))
    }
  })
}

const csrfToken = () => {
  const csrf = document.getElementsByName('csrf-token')[0]

  if (csrf) {
    return csrf.content;
  } else {
    return ''
  }
}
