
import { POSTCODE_API_URL } from './constants'

export const getAddressData = (post_code) => {
  const url = `${POSTCODE_API_URL}/${post_code}`
  return fetch(url, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }).then((response) =>
    response.json().then(data => ({
      data: data.result,
      status: response.status
    }))
  )
}
