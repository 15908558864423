export const GENDERS = ['male', 'female']

export const PERSON_RELATIONS = ['mother','father','grandmother','grandfather','son','daughter','brother','sister','aunt','uncle','cousin','husband','wife','partner','niece','nephew','friend']

export const ACCOUNT_TYPE_OPTIONS = { 'Me': 'direct', 'A friend or family member and me': 'joint', 'Friend or family member only': 'proxy' }

export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// UK government postcode regex
export const POSTCODE_REGEX = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/

// Minimum eight characters, at least one letter, one number and one special character:
export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/

export const URL_REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/

export const PROFILE_TYPES = { direct: 'DirectProfile', proxy: 'ProxyProfile', client: 'ClientProfile' }

export const REASON_TYPES = { direct: 'DirectReason', proxy: 'ProxyReason' }

export const ACCOUNT_TYPES = { direct: 'direct', proxy: 'proxy', joint: 'joint' }

export const QUICK_MESSAGE_KINDS = ['wave', 'handshake', 'supportive_hug', 'smile']

export const VALIDATION_MSG = { isRequired: 'Field is required', numberRequired: 'Must be number',
                                isEmail: 'This is not email', tooLong: 'Field is too long', isUrl: 'This is not valid site address' }

export const STATUS_GET_TOGETHER = { gathering_interest: 'Gathering Interest', definitely_on: 'Definitely ON', cancelled: 'Cancelled' }

export const MEETING_TYPE_GET_TOGETHER = { online: 'Online', in_person: 'In-person' }

export const TIME_PERIOD = ['AM', 'PM']

export const GET_TOGETHER_ACTIONS = { definitely: 'I’m going', leave: 'I can’t go anymore' }

export const POSTCODE_API_URL = 'https://postcodes.io/postcodes'

export const API_POSTCODE_FIELDS = ['postcode', 'admin_district', 'admin_county', 'region', 'country', 'longitude', 'latitude']

export const ALL_GENDERS = [{ label: 'Male', value: 'male'}, {label: 'Female', value:'female'}, {label: 'Agender', value:'agender'}, {label: 'Aporagender', value:'aporagender'}, {label: 'Bigender', value:'bigender'},
                         {label: 'Butch', value:'butch'}, {label: 'Cisgender', value:'cisgender'}, {label: 'Demigender', value:'demigender'}, {label: 'Femme', value:'femme'}, {label: 'Genderfluid', value:'genderfluid'},
                         {label: 'Gender neutral',value: 'gender neutral'}, {label: 'Gender queer', value:'gender queer'}, {label: 'Intergender', value:'intergender'},
                         {label: 'Nonbinary', value:'nonbinary'}, {label: 'Polygender', value: 'polygender'}, {label: 'Transgender', value: 'transgender' }]

export const CONTACT_FILTER_GENDERS = [ { label: 'MEN', value: 'male' },
                                        { label: 'WOMEN', value: 'female'},
                                        { label: 'OTHER', value: 'other' } ]

export const REPORT_REASONS = { offensive1: 'This profile is offensive', offensive2: 'This person was offensive in live chat', offensive3: 'Other (please give details below)' }

export const MESSAGE_REPORT_REASONS = { offensive1: 'This message is offensive', offensive2: 'I think this person may be a scammer', offensive3: 'Other (please give details below)' }

export const POST_REPORT_REASONS = { offensive1: 'The post is offensive or abusive', offensive2: 'The post is inappropriate for this group', offensive3: 'Other (please give details below)' }

export const CONFIRM_STATUS = { Reject: 'rejected', Approve: 'approved' }

export const REJECT_PHOTO_REASONS = { offensive1: 'offensive1', offensive2: 'offensive2', offensive3: 'offensive3' }

export const SESSION_TIME = { checkSeesion: 1000*60*44, logoutAfter: 1000*60 }

export const USERNAME_REGEX = /^([A-Z0-9])*[A-Z]+[0-9]*$/i

export const PROXY_REGEX = /^[A-Z-]+$/i

export const NOTICE_SHOW_DURATION = 6000

export const PHOTO_ROTATE_ANGLE = { 0: '0', 90: '90', 180: '180', 270: '270' }

export const MSG_MAX_CHARS_TO_SHOW = 200

export const FILTERED_MAIL_SUPPORT_URL = 'https://frindow.freshdesk.com/support/solutions/articles/60000687029-what-is-filtered-mail-'

export const QUESTIONNAIRE_SUPPORT_URL = 'https://frindow.freshdesk.com/support/solutions/articles/60000803148-getting-a-like-minded-score/'

export const FAVOURITES_INFO_LINK = 'https://frindow.freshdesk.com/support/solutions/articles/60000812353-what-are-favourites-'
