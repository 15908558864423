import moment from 'moment'

const genDays = () => {
  const days = {}
  for( let i = 1; i <= 31; i++) {
    const key = i.toString()
    days[key] = (key.length === 1 ? '0' + key : key)
  }
  return days
}

export const genYears = (reverse = false, fromYear = 1910, toYear = parseInt(moment().subtract(18,'years').format('YYYY'))) => {
  const years = []
  for( let i = fromYear; i <= toYear ; i++) { reverse ? years.unshift(i) : years.push(i) }
  return years
}

export const dates = {
  days: genDays(),
  months: { 'January': '01', 'February': '02', 'March': '03', 'April': '04', 'May': '05', 'June': '06',
            'July': '07', 'August': '08', 'September': '09', 'October': '10', 'November': '11', 'December': '12' },
  years: genYears(),
}

export default dates;
