import i18n from "i18next";

import translationEn from './translations/en/translations.json';

window.i18n = i18n

i18n.init({
  // we init with resources
  resources: {
    en: { translations: translationEn }
  },
  fallbackLng: 'en',
  debug: true,
  lng: 'en',

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  // keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  returnObjects: true,

  react: {
    wait: true
  }
});

export default i18n;

